import { IConfig } from './models'

// YO! nginx image runs this through env
const config: IConfig = {
  env: 'development',
  graphqlUri: '$HOST/hasura/v1/graphql',
  graphqlWsUri: '$WEBSOCKET_HOST/hasura/v1/graphql',
  backendUri: '$HOST/backend',
  receiverUri: 'https://faro-collector-prod-us-central-0.grafana.net/collect/d474ee4835e3723b2ae4d9b6fc7af180'
}

export const __cache_version = 1

try {
  // @ts-ignore
  window.__CONFIG__ = config
} catch (e) {}

export default config
